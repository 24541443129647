import { createContext, useCallback, useContext, useReducer } from "react";
import * as Service from "./service";
import settingsReducer from "./reducer";
import * as types from "./types";
import nftAbi from "config/abi/NFT.json";
import marketplaceAbi from "config/abi/MarketPlace.json";
import galleryAbi from "config/abi/Gallery.json";
import galleryFactoryAbi from "config/abi/GalleryFactory.json";
import tokenInfoAbi from "config/abi/TokenInfo.json";
import lazyMintingAbi from "config/abi/LazyMinting.json";
import {
  BINANCE_MAIN_NET_CHAIN_ID,
  BINANCE_TEST_NET_CHAIN_ID,
  ETHEREUM_MAIN_NET_CHAIN_ID,
  POLYGON_MAIN_NET_CHAIN_ID,
  POLYGON_TEST_NET_CHAIN_ID,
  ETHEREUM_TEST_NET_CHAIN_ID,
} from "constants/global-constants";

const intialState = {
  nft: {
    [POLYGON_MAIN_NET_CHAIN_ID]: "",
    [POLYGON_TEST_NET_CHAIN_ID]: "",
    [BINANCE_TEST_NET_CHAIN_ID]: "",
    [BINANCE_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_TEST_NET_CHAIN_ID]: "",
  },
  marketPlace: {
    [POLYGON_MAIN_NET_CHAIN_ID]: "",
    [POLYGON_TEST_NET_CHAIN_ID]: "",
    [BINANCE_TEST_NET_CHAIN_ID]: "",
    [BINANCE_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_TEST_NET_CHAIN_ID]: "",
  },
  galleryFactory: {
    [POLYGON_MAIN_NET_CHAIN_ID]: "",
    [POLYGON_TEST_NET_CHAIN_ID]: "",
    [BINANCE_TEST_NET_CHAIN_ID]: "",
    [BINANCE_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_TEST_NET_CHAIN_ID]: "",
  },
  tokenInfo: {
    [POLYGON_MAIN_NET_CHAIN_ID]: "",
    [POLYGON_TEST_NET_CHAIN_ID]: "",
    [BINANCE_TEST_NET_CHAIN_ID]: "",
    [BINANCE_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_TEST_NET_CHAIN_ID]: "",
  },
  lazyMinting: {
    [POLYGON_MAIN_NET_CHAIN_ID]: "",
    [POLYGON_TEST_NET_CHAIN_ID]: "",
    [BINANCE_TEST_NET_CHAIN_ID]: "",
    [BINANCE_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_MAIN_NET_CHAIN_ID]: "",
    [ETHEREUM_TEST_NET_CHAIN_ID]: "",
  },
  underConstruction: {
    [POLYGON_MAIN_NET_CHAIN_ID]: '',
    [POLYGON_TEST_NET_CHAIN_ID]:'',
    [BINANCE_TEST_NET_CHAIN_ID]: '',
    [BINANCE_MAIN_NET_CHAIN_ID]: '',
    [ETHEREUM_MAIN_NET_CHAIN_ID]: '',
    [ETHEREUM_TEST_NET_CHAIN_ID]: '',
  },

  gasFeeStatus: {
    [POLYGON_MAIN_NET_CHAIN_ID]: '',
    [POLYGON_TEST_NET_CHAIN_ID]:'',
    [BINANCE_TEST_NET_CHAIN_ID]: '',
    [BINANCE_MAIN_NET_CHAIN_ID]: '',
    [ETHEREUM_MAIN_NET_CHAIN_ID]: '',
    [ETHEREUM_TEST_NET_CHAIN_ID]: '',
  },

  abi: {
    nft: nftAbi.abi,
    galleryFactory: galleryFactoryAbi.abi,
    marketPlace: marketplaceAbi.abi,
    gallery: galleryAbi.abi,
    tokenInfo: tokenInfoAbi.abi,
    lazyMinting: lazyMintingAbi.abi,
  },
  version: "1",
};

const SettingsContext = createContext(intialState);

// const noOp = () => {};

export const SettingsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(settingsReducer, intialState);

  const handleListSettings = useCallback(async () => {
    try {
      const response = await Service.listSettings();
      return response;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleGetSettingsById = useCallback(async (id) => {
    try {
      const response = Service.getSettingsById(id);
      return response;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleListAndSetContractSettings = useCallback(async () => {
    try {
      const response = await Service.listSettings();
      let changedResponse = {};
      response.map((data) => {
        changedResponse = {
          nft: {
            ...changedResponse?.nft,
            [data.networkId]: data?.contracts?.nft ?? "",
          },
          marketPlace: {
            ...changedResponse?.marketPlace,
            [data.networkId]: data?.contracts?.marketPlace ?? "",
          },
          galleryFactory: {
            ...changedResponse?.galleryFactory,
            [data.networkId]: data?.contracts?.galleryFactory ?? "",
          },
          lazyMinting: {
            ...changedResponse?.lazyMinting,
            [data.networkId]: data?.contracts?.lazyMinting ?? "",
          },
          tokenInfo: {
            ...changedResponse?.tokenInfo,
            [data.networkId]: data?.contracts?.tokenInfo ?? "",
          },
          underConstruction:{
            ...changedResponse?.underConstruction,
            [data.networkId]:data?.underConstruction ?? false
          },
          gasFeeStatus:{
            ...changedResponse?.gasFeeStatus,
            [data.networkId]:data?.gasFeeStatus ?? "ProposeGasPrice"
          }
        };
      });
      dispatch({
        type: types.GET_SETTINGS_BY_CHAINID,
        res: changedResponse,
      });
      return changedResponse;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleGetSettingsByChainId = async (chainId) => {
    try {
      const response = await Service.getSettingsByChainId(chainId);
      let changedResponse = {
        nft: {
          [chainId]: response?.contracts?.nft ?? "",
        },
        marketPlace: {
          [chainId]: response?.contracts?.marketPlace ?? "",
        },
        galleryFactory: {
          [chainId]: response?.contracts?.galleryFactory ?? "",
        },
        version: response?.version ?? "",

        // abi: response?.abi,
      };
      dispatch({
        type: types.GET_SETTINGS_BY_CHAINID,
        res: response.networkId ? changedResponse : state,
      });

      return changedResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetSettingsByChainIdAndVersion = async (chainId, version) => {
    const response = await Service.getSettingByIdandVersion(chainId, version);
    let changedResponse = {
      nft: {
        [chainId]: response?.contracts?.nft ?? "",
      },
      marketPlace: {
        [chainId]: response?.contracts?.marketPlace ?? "",
      },
      galleryFactory: {
        [chainId]: response?.contracts?.galleryFactory ?? "",
      },
      version: response?.version ?? "",
    };
    dispatch({
      type: types.GET_SETTINGS_BY_CHAINID,
      res: response.networkId ? changedResponse : state,
    });
    return changedResponse;
  };

  return (
    <SettingsContext.Provider
      value={{
        handleListSettings,
        handleGetSettingsById,
        handleGetSettingsByChainId,
        handleGetSettingsByChainIdAndVersion,
        handleListAndSetContractSettings,
        ...state,
        // settings: state,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => useContext(SettingsContext);
