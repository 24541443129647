import {
  POLYGON_MAIN,
  POLYGON_TEST,
  BINANCE_TEST,
  BINANCE_MAIN,
  ETHEREUM_MAIN,
  ETHEREUM_TEST,
  POLYGON_TEST_NET_CHAIN_ID,
  POLYGON_MAIN_NET_CHAIN_ID,
  BINANCE_MAIN_NET_CHAIN_ID,
  BINANCE_TEST_NET_CHAIN_ID,
  ETHEREUM_MAIN_NET_CHAIN_ID,
  ETHEREUM_TEST_NET_CHAIN_ID,
  DEFAULT_CHAIN_ID,
} from "./global-constants";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const CHAIN_IDS = {
  networkId: {
    [POLYGON_TEST]: POLYGON_TEST_NET_CHAIN_ID,
    [POLYGON_MAIN]: POLYGON_MAIN_NET_CHAIN_ID,
    [BINANCE_TEST]: BINANCE_TEST_NET_CHAIN_ID,
    [BINANCE_MAIN]: BINANCE_MAIN_NET_CHAIN_ID,
    [ETHEREUM_MAIN]: ETHEREUM_MAIN_NET_CHAIN_ID,
    [ETHEREUM_TEST]: ETHEREUM_TEST_NET_CHAIN_ID,
  },
  networkIdHex: {
    [POLYGON_TEST]: "0x13881",
    [POLYGON_MAIN]: "0x89",
    [BINANCE_MAIN]: "0x38",
    [BINANCE_TEST]: "0x61",
    [ETHEREUM_MAIN]: "0x1",
    [ETHEREUM_TEST]: "0x5",
  },
  networkType: {
    [POLYGON_TEST]: POLYGON_TEST,
    [POLYGON_MAIN]: POLYGON_MAIN,
    [BINANCE_MAIN]: BINANCE_MAIN,
    [BINANCE_TEST]: BINANCE_TEST,
    [ETHEREUM_TEST]: ETHEREUM_TEST,
    [ETHEREUM_MAIN]: ETHEREUM_MAIN,
  },
};

export const CHAIN_RPC_URLS = {
  [POLYGON_MAIN]: [
    "https://polygon-mainnet.g.alchemy.com/v2/yuGTsAVDqYXyoLJzjB0g3f9tvVPgR85G",
  ],
  [POLYGON_TEST]: [
    "https://polygon-mumbai.g.alchemy.com/v2/QgYOFbUUBWIKOCTtKpB4wSYMiZovErfI",
  ],
  [BINANCE_MAIN]: ["https://bscrpc.com"],
  [BINANCE_TEST]: ["https://data-seed-prebsc-2-s1.binance.org:8545"],
  [ETHEREUM_MAIN]: [
    "https://eth-mainnet.g.alchemy.com/v2/bnfGi0PVbNMijQJFjFng2De86z-QvOMR",
  ],
  [ETHEREUM_TEST]: [
    "https://eth-goerli.g.alchemy.com/v2/qax6_FfZgloG4wvUgdVuTUAOCYsRLmaz",
  ],
};

export const CHAIN_RPC_URL_CHAINID = {
  [POLYGON_MAIN_NET_CHAIN_ID]: [
    "https://polygon-mainnet.g.alchemy.com/v2/yuGTsAVDqYXyoLJzjB0g3f9tvVPgR85G",
  ],
  [POLYGON_TEST_NET_CHAIN_ID]: [
    "https://polygon-mumbai.g.alchemy.com/v2/QgYOFbUUBWIKOCTtKpB4wSYMiZovErfI",
  ],
  [BINANCE_MAIN_NET_CHAIN_ID]: ["https://bscrpc.com"],
  [BINANCE_TEST_NET_CHAIN_ID]: [
    "https://data-seed-prebsc-1-s3.binance.org:8545",
  ],
  [ETHEREUM_MAIN_NET_CHAIN_ID]: [
    "https://eth-mainnet.g.alchemy.com/v2/bnfGi0PVbNMijQJFjFng2De86z-QvOMR",
  ],
  [ETHEREUM_TEST_NET_CHAIN_ID]: [
    "https://eth-goerli.g.alchemy.com/v2/qax6_FfZgloG4wvUgdVuTUAOCYsRLmaz",
  ],
};

export const CHAIN_EXPLORERS = {
  [POLYGON_MAIN_NET_CHAIN_ID]: ["https://polygonscan.com/"],
  [POLYGON_TEST_NET_CHAIN_ID]: ["https://mumbai.polygonscan.com/"],
  [BINANCE_MAIN_NET_CHAIN_ID]: ["https://bscscan.com"],
  [BINANCE_TEST_NET_CHAIN_ID]: ["https://testnet.bscscan.com"],
  [ETHEREUM_MAIN_NET_CHAIN_ID]: ["https://etherscan.io"],
  [ETHEREUM_TEST_NET_CHAIN_ID]: ["https://goerli.etherscan.io"],
};

export const COMMON_BASE_TOKENS = {
  [POLYGON_TEST_NET_CHAIN_ID]: {
    symbol: "MATIC",
    name: "Matic Test",
    priceId: "maticTest",
    decimals: 18,
    imgSrc: "/img/misc/matic.png",
  },
  [POLYGON_MAIN_NET_CHAIN_ID]: {
    symbol: "MATIC",
    name: "Matic",
    priceId: "matic",
    decimals: 18,
    imgSrc: "/img/misc/matic.png",
  },
  [BINANCE_TEST_NET_CHAIN_ID]: {
    symbol: "BNB",
    name: "BNB",
    priceId: "bnbTest",
    decimals: 18,
    imgSrc: "/img/misc/binance-coin.png",
  },
  [BINANCE_MAIN_NET_CHAIN_ID]: {
    symbol: "BNB",
    name: "BNB",
    priceId: "bnb",
    decimals: 18,
    imgSrc: "/img/misc/binance-coin.png",
  },
  [ETHEREUM_TEST_NET_CHAIN_ID]: {
    symbol: "ETH",
    name: "ETH Test",
    priceId: "eth",
    decimals: 18,
    imgSrc: "/img/misc/ethereum.png",
  },
  [ETHEREUM_MAIN_NET_CHAIN_ID]: {
    symbol: "ETH",
    name: "ETH Main",
    priceId: "eth",
    decimals: 18,
    imgSrc: "/img/misc/ethereum.png",
  },

  [DEFAULT_CHAIN_ID]: {
    symbol: "MATIC",
    name: "Matic Test",
    priceId: "maticTest",
    decimals: 18,
    imgSrc: "/img/misc/matic.png",
  },
};
